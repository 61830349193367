import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Alert,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import axios from 'axios';
import baseUrl from '../config';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  border: '2px solid #d3d3d3',
  color: 'black',
  padding: '10px 24px',
  textTransform: 'none',
  position: 'relative',
  '&:before, &:after': {
    content: '"•"',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '20px',
    color: 'black',
  },
  '&:before': {
    left: '10px',
  },
  '&:after': {
    right: '10px',
  },
  '& .MuiButton-label': {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const ClassCard = ({ classData }) => {
  const {
    course = 'No Course Title',
    schedule = [],
  } = classData || {};

  const scheduleItem = schedule[0] || {};

  const isUpcoming = (date, time) => {
    const scheduleDate = new Date(date);
    const [hours, minutes] = time.split(':').map(Number);
    scheduleDate.setHours(hours, minutes, 0, 0);
    return scheduleDate > new Date();
  };

  if (!scheduleItem.date || !scheduleItem.time) {
    return <Typography>No schedule available.</Typography>;
  }

  return (
    <Card sx={{
      borderRadius: '12px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '300px',
      position: 'relative',
      '&:hover': {
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        transform: 'scale(1.02)',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      },
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    }}>
      <CardContent>
        <Typography variant="h6">{course}</Typography>
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTimeIcon sx={{ fontSize: 20, mr: 1, color: 'black' }} />
          <Typography variant="body2" sx={{ fontWeight: 600 }}>Date:</Typography>
          <Typography variant="body2" sx={{ ml: 1 }}>{new Date(scheduleItem.date).toLocaleDateString()}</Typography>
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeIcon sx={{ fontSize: 20, mr: 1, color: 'black' }} />
          <Typography variant="body2" sx={{ fontWeight: 600 }}>Time:</Typography>
          <Typography variant="body2" sx={{ ml: 1 }}>{scheduleItem.time}</Typography>
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {scheduleItem.joinUrl && isUpcoming(scheduleItem.date, scheduleItem.time) && (
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={() => window.open(scheduleItem.joinUrl, '_blank')}
              sx={{ mt: 2 }}
            >
              Join Class
            </StyledButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const ClassSummery = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [classesData, setClassesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClassSchedules = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/class-schedule`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const processedClasses = response.data.map((classItem) => ({
          course: classItem.course,
          schedule: classItem.schedule.map((schedule) => {
            const dateValue = new Date(schedule.startDate);
            if (isNaN(dateValue)) {
              console.error(`Invalid date: ${schedule.startDate}`);
              return null; // Skip invalid dates
            }
            return {
              date: dateValue.toISOString(),
              time: schedule.time,
              zoomMeetingId: schedule.zoomMeetingId,
              joinUrl: schedule.joinUrl,
            };
          }).filter(Boolean), // Remove any null entries
        }));

        setClassesData(processedClasses);
      } catch (err) {
        console.log(err);
        setError(err?.response?.data?.message || 'An error occurred while fetching schedules');
      } finally {
        setLoading(false);
      }
    };

    fetchClassSchedules();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (loading) return <CircularProgress />;
  if (error) return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        overflowY: 'auto',
        position: 'relative',
        backgroundColor: '#bac2ff',
      }}
    >
      <Alert severity="error">{error}</Alert>
    </Box>
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        overflowY: 'auto',
        position: 'relative',
        backgroundColor: '#bac2ff',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Box>
          <Typography variant="h5" component="div">Class Summary</Typography>
          <Typography variant="body2" color="textSecondary">Take control of your learning journey</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>Newest</MenuItem>
          <MenuItem onClick={handleMenuClose}>Oldest</MenuItem>
        </Menu>
      </Box>
      <Grid container spacing={3}>
        {classesData.map((classData, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ClassCard classData={classData} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ClassSummery;
