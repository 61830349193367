import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faBell, faHistory } from '@fortawesome/free-solid-svg-icons';
import {
  faTachometerAlt, faUser, faClipboardList, faCertificate, faTasks, faStar
} from '@fortawesome/free-solid-svg-icons';
import { oratricslogo, customer } from "../Assets/index";
import Notifications from '../Components/Notification'; // Import Notifications component
import { Menu, MenuItem, IconButton, Typography, Divider } from '@mui/material';

const ManubarPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const menuItems = [
    { name: "Dashboard", icon: faTachometerAlt, link: "/" },
    { name: "Class Summary", icon: faUser, link: "/classsumery" },
    { name: "Activity Classes", icon: faClipboardList, link: "/activityclass" },
    { name: "Achievements", icon: faCertificate, link: "/achievments" },
    { name: "Assessments", icon: faTasks, link: "/assessments" },
    { name: "My Rewards", icon: faStar, link: "/reward" },
    { name: "My Class History", icon: faHistory, link: "/history" },

  ];

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowMenu(false); // Close the menu when an item is clicked
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Mobile Header Navbar */}
      <header className="lg:hidden fixed top-0 left-0 w-full bg-white shadow-md z-50 flex items-center justify-between p-4" style={{ height: '80px' }}>
        <button
          className="p-5 text-gray-700"
          onClick={() => setShowMenu(!showMenu)}
        >
          <FontAwesomeIcon className="h-8 " icon={showMenu ? faTimes : faBars} />
        </button>
        <img src={oratricslogo} alt="Company Logo" className="h-14 w-auto" />
        <IconButton
          edge="end"
          color="inherit"
          aria-label="notifications"
          onClick={handleNotificationClick}
        >
          <FontAwesomeIcon icon={faBell} />
        </IconButton>
      </header>

      {/* Mobile Sidebar Menu */}
      <div
        className={`fixed top-5 left-0 w-64 h-full bg-gray-200 text-gray-500 z-40 transition-transform duration-300 ease-in-out lg:hidden ${showMenu ? 'translate-x-0' : '-translate-x-full'}`}
        style={{ height: '100vh', overflowY: 'auto', backgroundColor: '#fff', padding: 30 }}
      >
        <div className="flex flex-col h-full w-full bg-white">
          {/* Menu Items */}
          <div className="flex-1 flex flex-col pt-10">
            {menuItems.map((item, index) => (
              <a
                key={index}
                href={item.link}
                style={{color:  selectedItem === item ? "text-white #bac2ff" : "#09135c"
                }  }
                className={`px-4 py-3 flex items-center cursor-pointer w-full ${
                  selectedItem === item ? "text-white #050a30" : "#050a30"
                } ${
                  selectedItem === item ? "rounded-lg mb-2" : "hover:bg-blue-100 hover:rounded-lg mb-2"
                }`}
                onClick={() => handleItemClick(item)}
              >
                <FontAwesomeIcon icon={item.icon} className="h-10 w-10 mr-7" />
                <span className="text-base font-medium">{item.name}</span>
              </a>
            ))}
          </div>

          {/* Footer Section */}
          <div className="px-4 py-3 mt-auto">
            <div className="flex items-center">
              <img src={customer} alt="BrightBuddy" className="h-10 w-10 mr-3" />
              <span style={{color:'#050a30'}} className=" font-semibold">Oratrics</span>
            </div>
            <div className="mt-4 text-gray-600 text-sm">
              <p>Need Help</p>
              <a href="#" style={{color:'#050a30'}} className="">
                Ask Oratrics
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Sidebar Menu */}
      <div className="hidden lg:block lg:sticky lg:top-0 bg-gray-200 text-gray-500"
        style={{
          height: '100vh',
          overflowY: 'auto',
          backgroundColor: '#fff',
          padding: 30,
        }}
      >
        <div className="flex flex-col h-full w-full lg:w-64 bg-white">
          {/* Logo Section */}
          <div className="flex items-center justify-center h-24">
            <img src={oratricslogo} alt="Company Logo" className="h-22 w-auto" />
          </div>

          {/* Menu Items */}
          <div className="flex-1 flex flex-col mt-5">
            {menuItems.map((item, index) => (
              <a
                key={index}
                href={item.link}
                style={{color:  selectedItem === item ? "text-white #bac2ff" : "#09135c"
              }  }
                className={`px-4 py-3 flex items-center cursor-pointer w-full ${
                  selectedItem === item ? "text-white #050a30" : "#050a30"
                } ${
                  selectedItem === item ? "rounded-lg mb-2" : "hover:bg-blue-100 hover:rounded-lg mb-2"
                }`}
                onClick={() => handleItemClick(item)}
              >
                <FontAwesomeIcon icon={item.icon} className="h-8 w-8 mr-5" />
                <span className="text-base font-medium">{item.name}</span>
              </a>
            ))}
          </div>

          {/* Footer Section */}
          <div className="px-4 py-3 mt-auto">
            <div className="flex items-center">
              <img src={customer} alt="BrightBuddy" className="h-10 w-10 mr-3" />
              <span style={{color:'#050a30'}} className="text-pink-700 font-semibold">Oratrics</span>
            </div>
            <div className="mt-4 text-gray-600 text-sm">
              <p>Need Help</p>
              <a href="#" style={{color:'#050a30'}}>
                Ask Oratrics
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Notifications Dropdown */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationClose}
      >
        <Notifications />
        <Divider />
        <MenuItem onClick={handleNotificationClose}>Close</MenuItem>
      </Menu>
    </>
  );
};

export default ManubarPage;
