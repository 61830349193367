// App.js
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  DashboardPage,
  Login,
  Register,
  ContentManagement,
  HomeService,
  MarketPlace,
  Settings,
  ShowcaseManagement,
} from './Pages/index';
import TestScheduleMeeting from './Pages/TestScheduleMSg';
import CartificatePage from './Pages/CartificatePage';
import AssessmentsPage from './Pages/AssessmentsPage';
import ClassSummeryPage from './Pages/ClassSummeryPage';
import EnrichmentClassesPage from './Pages/EnrichmentClassesPage';
import RewardPage from './Pages/RewardPage';
import Layout from './Layout'; // Adjust import path as needed
import SalesProfileForm from './Pages/SalesProfileForm';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import PdfViewer from './Components/PdfViewer';
import PptViewer from './Components/PptViewer';
import StudentHistory from './Components/ClassHistory';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<DashboardPage />} />
            <Route path="/reward" element={<RewardPage />} />
            <Route path="/view-pdf" element={<PdfViewer />} />
            <Route path="/view-ppt" element={<PptViewer />} />
            <Route path="/content-management" element={<ContentManagement />} />
            <Route path="/activityclass" element={<EnrichmentClassesPage />} />
            <Route path="/home-service" element={<HomeService />} />
            <Route path="/market-place" element={<MarketPlace />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/showcase-management" element={<ShowcaseManagement />} />
            <Route path="/classsumery" element={<ClassSummeryPage />} />
            <Route path="/assessments" element={<AssessmentsPage />} />
            <Route path="/achievments" element={<CartificatePage />} />
            <Route path="/assessmentss" element={<TestScheduleMeeting />} />
            <Route path="/sale" element={<SalesProfileForm />} />
            <Route path="/history" element={<StudentHistory />} />

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
