import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, LinearProgress, Avatar } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import baseUrl from '../config';
import axios from 'axios';

// Function to calculate the number of days between two dates
const calculateDaysBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInTime = end.getTime() - start.getTime();
  return Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert milliseconds to days
};

const LearningProgress = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [userName, setUserName] = useState('');

  // Function to fetch upcoming courses
  const fetchUpcomingCourses = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/students/enrolled-courses`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      

      if (response.status === 200) {
        const data = response.data;

        // Restructure data to include fields inside 'course' and relevant teacher names
        const transformedData = data.map(item => ({
          ...item,
          course: {
            ...item.course,
            title: item.course.title,
            description: item.course.description,
            startDate: item.course.startDate,
            endDate: item.course.schedules[0]?.date, // Use the first schedule's date as end date
            bookedClasses: item.course.bookedClasses,
            completedClasses: item.course.completedClasses,
            progress: item.course.progress,
            studentName: item.studentName, // Assuming studentName is passed as well
            teacherName: item.enrollmentTeacherName // Use the teacher name from the response
          }
        }));
        
        setCoursesData(transformedData);
      } else {
        console.error('Failed to fetch courses:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  // Function to fetch user details
  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserName(user.name || 'User');
    }
  };

  useEffect(() => {
    fetchUpcomingCourses();
    fetchUserDetails();
  }, []);

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto' }}>
      <Typography variant="h6" fontWeight="bold" mb={2}>My Learning Progress</Typography>

      {/* Display course cards */}
      {coursesData.map(course => {
        const { startDate, endDate, progress, bookedClasses, teacherName } = course.course || {};
        const daysRemaining = endDate ? calculateDaysBetweenDates(new Date(), endDate) : null;

        return (
          <Card key={course._id} sx={{ borderRadius: 4, boxShadow: 3, mb: 2 }}>
            <CardContent>
              <Typography variant="subtitle2" color="primary" mb={1}>1 on 1 online classes</Typography>

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Avatar src="/path/to/robotics-icon.png" sx={{ width: 40, height: 40, mr: 2 }} />
                  <Typography variant="h6">{course.course?.title}</Typography>
                </Box>
                <LockIcon color="action" />
              </Box>

              <Box display="flex" alignItems="center" my={2}>
                <LinearProgress variant="determinate" value={progress || 0} sx={{ flexGrow: 1, mr: 2 }} />
                <Typography variant="caption">{progress || 0}/{bookedClasses || 0} Completed</Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" textAlign="center" mb={2}>
                <Box>
                  <Typography variant="h6">{bookedClasses || 0}</Typography>
                  <Typography variant="caption" color="textSecondary">Booked classes</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">{progress || 0}</Typography>
                  <Typography variant="caption" color="textSecondary">Completed classes</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">0</Typography>
                  <Typography variant="caption" color="textSecondary">Lost classes</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" mb={2}>
                <Avatar src="/path/to/student-avatar.png" sx={{ width: 40, height: 40, mr: 2 }} />
                <Typography>{course.course.enrollmentTeacherName}</Typography>
              </Box>

              {daysRemaining !== null && (
                <Typography variant="body2" color="textSecondary">
                  Days Remaining: {daysRemaining} days
                </Typography>
              )}
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default LearningProgress;
