import React from 'react';
import {
  Box,
} from '@mui/material';
import Dashboards from '../Components/Dashboard';
import SideScreen from '../Components/SideScreen';

const DashboardPage = () => {
  return (
    <Box 
    display="flex"  
    sx={{        backgroundColor: '#d3d7f8ff',
    }}
    flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >
        <Dashboards/>
        <Box 
  height={{ md: '120vh' }}
    
    flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >
      <SideScreen 
        
      />
      </Box>
    </Box>
  );
};

export default DashboardPage;
