import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Card,
  Typography,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Box,
  AppBar,
  Toolbar,
  Snackbar,
  Chip,
} from '@mui/material';
import { Brightness4, Brightness7, Delete } from '@mui/icons-material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { format } from 'date-fns';
import baseUrl from '../config';

const headerImages = [
  'https://via.placeholder.com/150/FF5733/FFFFFF?text=Image+1',
  'https://via.placeholder.com/150/33FF57/FFFFFF?text=Image+2',
  'https://via.placeholder.com/150/3357FF/FFFFFF?text=Image+3',
  'https://via.placeholder.com/150/5733FF/FFFFFF?text=Image+4',
  'https://via.placeholder.com/150/FF33A1/FFFFFF?text=Image+5',
];

const CreateNotes = () => {
  const [note, setNote] = useState({
    text: '',
    headerImage: '',
    title: '',
    tags: [],
    pinned: false,
    dueDate: null,
  });
  const [darkMode, setDarkMode] = useState(false);
  const [fontStyle, setFontStyle] = useState({ bold: false, italic: false, underline: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tagInput, setTagInput] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [error, setError] = useState('');

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
  console.log(user,"user");
  
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);


  useEffect(() => {
    const savedNote = JSON.parse(localStorage.getItem('note'));
    if (savedNote) setNote(savedNote);
  }, []);

  const handleChange = (event) => {
    setNote((prevNote) => ({ ...prevNote, text: event.target.value }));
  };

  const handleTitleChange = (event) => {
    setNote((prevNote) => ({ ...prevNote, title: event.target.value }));
  };

  const handleDueDateChange = (date) => {
    setNote((prevNote) => ({ ...prevNote, dueDate: date }));
    setDueDate(date);
  };

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  const saveNote = async () => {
    try {
      const token = localStorage.getItem('token'); // Assume token is stored in local storage
      const response = await axios.post(`${baseUrl}api/students/createnotes`, {
        content: note.text,
        title: note.title, // Ensure the title is included
        headerImage: note.headerImage,
        tags: note.tags,
        pinned: note.pinned,
        dueDate: note.dueDate,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data); // Handle response
      localStorage.setItem('note', JSON.stringify(note));
      setSnackbarOpen(true);
    } catch (error) {
      setError('Failed to save note. Please try again.');
      console.error(error);
    }
  };
  

  const handleImageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleImageSelect = (image) => {
    setNote((prevNote) => ({ ...prevNote, headerImage: image }));
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const addTag = () => {
    if (tagInput.trim()) {
      setNote((prevNote) => ({
        ...prevNote,
        tags: [...prevNote.tags, tagInput.trim()],
      }));
      setTagInput('');
    }
  };

  const deleteTag = (tagIndex) => {
    setNote((prevNote) => ({
      ...prevNote,
      tags: prevNote.tags.filter((_, index) => index !== tagIndex),
    }));
  };

  const deleteNote = () => {
    setNote({
      text: '',
      headerImage: '',
      title: '',
      tags: [],
      pinned: false,
      dueDate: null,
    });
  };

  const togglePinNote = () => {
    setNote((prevNote) => ({ ...prevNote, pinned: !prevNote.pinned }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError('');
  };

  const toggleFontStyle = (style) => {
    setFontStyle((prev) => ({
      ...prev,
      [style]: !prev[style],
    }));
  };

  return (
    <Container
    //   maxWidth="lg"
      style={{
        marginTop: '20px',
        backgroundColor: darkMode ? '#1e1e1e' : '#7b9feb',
        color: darkMode ? '#ffffff' : '#000000',
        padding: '20px',
        borderRadius: '8px',
      }}
    >
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h6">Writing Space</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Toggle light/dark mode">
            <IconButton onClick={toggleTheme}>
              {darkMode ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
          </Tooltip>
          <Button variant="contained" color="secondary" onClick={saveNote}>
            Save Note
          </Button>
        </Toolbar>
      </AppBar>

      <div style={{ margin: '20px 0' }} />

      <Card style={{ padding: '20px', marginBottom: '20px', boxShadow: darkMode ? '0 4px 8px rgba(255, 255, 255, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        {note.headerImage && (
          <img
            src={note.headerImage}
            alt="Header"
            style={{ width: '100%', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              variant="outlined"
              value={note.title}
              onChange={handleTitleChange}
              fullWidth
              style={{ marginBottom: '10px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="date"
              variant="outlined"
              value={dueDate || (note.dueDate ? format(new Date(note.dueDate), 'yyyy-MM-dd') : '')}
              onChange={(e) => handleDueDateChange(e.target.value)}
              style={{ marginBottom: '10px', width: '100%' }}
            />
          </Grid>
        </Grid>
        <Box display="flex" flexWrap="wrap" marginBottom="10px">
          {note.tags.map((tag, index) => (
            <Chip key={index} label={tag} onDelete={() => deleteTag(index)} style={{ margin: '2px' }} />
          ))}
        </Box>
        <TextField
          label="Add Tag"
          variant="outlined"
          value={tagInput}
          onChange={(e) => setTagInput(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && addTag()}
          style={{ marginBottom: '10px', width: '100%' }}
        />
        <Box display="flex" alignItems="center" marginBottom="10px">
          <Button variant="outlined" onClick={() => toggleFontStyle('bold')} style={{ marginRight: '10px' }}>
            <strong>B</strong>
          </Button>
          <Button variant="outlined" onClick={() => toggleFontStyle('italic')} style={{ marginRight: '10px' }}>
            <em>I</em>
          </Button>
          <Button variant="outlined" onClick={() => toggleFontStyle('underline')} style={{ marginRight: '10px' }}>
            <u>U</u>
          </Button>
          <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Add Header Image">
              <IconButton onClick={handleImageClick}>
                <AddAPhotoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={note.pinned ? 'Unpin Note' : 'Pin Note'}>
              <IconButton onClick={togglePinNote}>
                {note.pinned ? '📌' : '📍'}
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Note">
              <IconButton onClick={deleteNote}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Grid>
        </Box>
        <TextField
          label="Write your note here..."
          multiline
          rows={13}
          variant="outlined"
          fullWidth
          value={note.text}
          onChange={handleChange}
          style={{
            backgroundColor: darkMode ? '#2e2e2e' : '#fff',
            fontWeight: fontStyle.bold ? 'bold' : 'normal',
            fontStyle: fontStyle.italic ? 'italic' : 'normal',
            textDecoration: fontStyle.underline ? 'underline' : 'none',
          }}
        />
        <Typography variant="caption" style={{ marginTop: '5px' }}>
          {`Word Count: ${note.text.split(' ').filter(Boolean).length} | Character Count: ${note.text.length}`}
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {headerImages.map((image, index) => (
            <MenuItem key={index} onClick={() => handleImageSelect(image)}>
              <img src={image} alt={`Header ${index + 1}`} style={{ width: '50px', marginRight: '10px' }} />
              {`Image ${index + 1}`}
            </MenuItem>
          ))}
        </Menu>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={error || "Note saved!"}
      />
    </Container>
  );
};

export default CreateNotes;
