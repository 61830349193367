// import React,{useState} from 'react';
// import { Card, CardContent, Box, Typography, Avatar, Button, Grid, IconButton, Pagination } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import DiamondIcon from '@mui/icons-material/Diamond';
// import CertificateCriteria from './CertificateCriteria';

// const EnrichmentClasses = () => {
//   const [drawerOpen, setDrawerOpen] = useState(false);

//   const handleClickOpen = () => setDrawerOpen(true);
//   const handleClickClose = () => setDrawerOpen(false);

//   const classes = [
//     {
//       id: 1,
//       title: 'Upper-Beginners: Be Piet Mondrian',
//       time: '03:00 PM, 09 Aug 2024',
//       instructor: 'Michaela Daniel',
//       icon: '/path/to/class-icon-1.png',
//       booked: true
//     },
//     {
//       id: 2,
//       title: 'Upper-Intermediate: Country of the Italy',
//       time: '03:30 PM, 09 Aug 2024',
//       instructor: 'Jack van Niekerk',
//       icon: '/path/to/class-icon-2.png',
//       booked: true
//     },
//     {
//       id: 3,
//       title: 'Beginners: Group Lesson 14',
//       time: '01:30 PM, 10 Aug 2024',
//       instructor: 'Michaela Daniel',
//       icon: '/path/to/class-icon-3.png',
//       booked: true
//     },
//     {
//       id: 4,
//       title: 'Upper-Beginners: Group Lesson L2U1',
//       time: '02:00 PM, 10 Aug 2024',
//       instructor: 'Erica Bosman',
//       icon: '/path/to/class-icon-4.png',
//       booked: false
//     },
//     {
//       id: 5,
//       title: 'Beginners: The Sydney Opera House',
//       time: '01:00 PM, 11 Aug 2024',
//       instructor: 'Nicole Sauls',
//       icon: '/path/to/class-icon-5.png',
//       booked: true
//     },
//     {
//       id: 6,
//       title: 'Upper-Beginners: Harry Potter\'s Pot...',
//       time: '02:30 PM, 11 Aug 2024',
//       instructor: 'Lizette Lubbe',
//       icon: '/path/to/class-icon-6.png',
//       booked: true
//     }
//   ];

//   return (
//     <>
//     <Box
//     sx={{
//       flex: 1,
//       display: 'flex',
//       flexDirection: 'column',
//       padding: 2,
//       overflowY: 'auto',
//       position: 'relative',
//       backgroundColor: '#fdd7ed',
//       // Ensure positioning context for sidebar
//     }}
//   >      <Box display="flex" alignItems="center" mb={2}>
//         <IconButton aria-label="back">
//           <ArrowBackIcon />
//         </IconButton>
//         <Typography variant="h6" fontWeight="bold">Enrichment Classes</Typography>
//       </Box>
//       <Typography variant="subtitle1" color="textSecondary" mb={3}>
//         Take control of your learning journey
//       </Typography>
//       <Grid container spacing={4}>
//         {classes.map((classItem, index) => (
//           <Grid item xs={12} sm={8} md={4} key={index}>
//             <Card sx={{ borderRadius: 4, boxShadow: 3 }}>
//               <CardContent>
//                 <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
//                   <Avatar src={classItem.icon} sx={{ width: 40, height: 40 }} />
//                   <Box display="flex" alignItems="center">
//                     <Typography variant="caption" fontWeight="bold" mr={0.5}>15</Typography>
//                     <DiamondIcon color="primary" />
//                   </Box>
//                 </Box>
//                 <Typography variant="body1" fontWeight="bold" mb={1}>
//                   {classItem.title}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary" mb={1}>
//                   {classItem.time}
//                 </Typography>
//                 <Box display="flex" alignItems="center" mb={2}>
//                   <Avatar src="/path/to/instructor-avatar.png" sx={{ width: 24, height: 24, mr: 1 }} />
//                   <Typography variant="body2">{classItem.instructor}</Typography>
//                 </Box>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   fullWidth
//                   sx={{ textTransform: 'none' }}
//                   disabled={!classItem.booked}
//                   onClick={handleClickOpen}  // Open drawer when clicking "Book Now"
//                 >
//                   Book Now for 15 <DiamondIcon sx={{ ml: 1 }} />
//                 </Button>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       <Box mt={4} display="flex" justifyContent="center">
//         <Pagination count={4} page={1} variant="outlined" shape="rounded" />
//       </Box>

//       {/* Add CertificateCriteria drawer here */}
//       <CertificateCriteria open={drawerOpen} onClose={handleClickClose} />
//     </Box>
//     </>
//   );
// };

// export default EnrichmentClasses;


import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, Avatar, Button, Grid, IconButton, Pagination } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DiamondIcon from '@mui/icons-material/Diamond';
import CertificateCriteria from './CertificateCriteria';
import baseUrl from '../config';
import { micbackground } from '../Assets';

const EnrichmentClasses = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [classes, setClasses] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [page, setPage] = useState(1);
const show = false
  const handleClickOpen = () => setDrawerOpen(true);
  const handleClickClose = () => setDrawerOpen(false);

  const fetchEnrichmentClasses = async () => {
    try {
      const response = await fetch(baseUrl+'api/students/enrichment-classes', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token-based auth
        },
      });
      const data = await response.json();
      if (response.ok) {
        setClasses(data.classes); // Assuming the response is an object with a classes property
        setTotalPages(data.totalPages); // Set totalPages if your API provides pagination info
      } else {
        console.error('Failed to fetch classes:', data.message);
      }
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  useEffect(() => {
    fetchEnrichmentClasses();
  }, [page]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          overflowY: 'auto',
          position: 'relative',
          backgroundImage:`url(${micbackground})`,
          backgroundSize:'cover'
          // Ensure positioning context for sidebar
        }}
      >
       {show? <> <Box display="flex" alignItems="center" mb={2}>
          <IconButton aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight="bold">Activity Classes</Typography>
        </Box>
        <Typography variant="subtitle1" color="textSecondary" mb={3}>
          Take control of your learning journey
        </Typography>
        <Grid container spacing={4}>
          {classes.map((classItem) => (
            <Grid item xs={12} sm={8} md={4} key={classItem._id}>
              <Card sx={{ borderRadius: 4, boxShadow: 3 }}>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Avatar src={classItem.icon} sx={{ width: 40, height: 40 }} />
                    <Box display="flex" alignItems="center">
                      <Typography variant="caption" fontWeight="bold" mr={0.5}>15</Typography>
                      <DiamondIcon color="primary" />
                    </Box>
                  </Box>
                  <Typography variant="body1" fontWeight="bold" mb={1}>
                    {classItem.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={1}>
                    {classItem.time}
                  </Typography>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar src="/path/to/instructor-avatar.png" sx={{ width: 24, height: 24, mr: 1 }} />
                    <Typography variant="body2">{classItem.instructor}</Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ textTransform: 'none' }}
                    disabled={!classItem.booked}
                    onClick={handleClickOpen}  // Open drawer when clicking "Book Now"
                  >
                    Book Now for 15 <DiamondIcon sx={{ ml: 1 }} />
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box mt={4} display="flex" justifyContent="center">
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            variant="outlined"
            shape="rounded"
          />
        </Box>

        {/* Add CertificateCriteria drawer here */}
        <CertificateCriteria open={drawerOpen} onClose={handleClickClose} /></>:
      <> <Box display="flex" alignItems="center" mb={2}>
      <IconButton aria-label="back">
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6" fontWeight="bold" color={"#fff"}>Activity Classes</Typography>
    </Box>
   
    <Grid container spacing={4}>
     
    </Grid>

    <Box mt={4} display="flex" justifyContent="center" height={"100%"} alignItems="center">
    <Box sx={{
      padding:5,
              backgroundColor: 'rgba(255, 255, 255, 0.63) ', borderRadius: '20%',
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              color: 'white', fontWeight: 'bold'
            }}>
    <Typography variant="subtitle1" color="textSecondary" fontSize={40} mb={3}>
    Unlock after first 5 Classes   </Typography>
    </Box>
    </Box>

    {/* Add CertificateCriteria drawer here */}
    <CertificateCriteria open={drawerOpen} onClose={handleClickClose} /></>}
      </Box>
    </>
  );
};

export default EnrichmentClasses;
