import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { oratricslogo, portrait } from "../Assets/index";
import "../index.css"; // Adjust if needed
import { Button } from "@mui/material";
import baseUrl from "../config";
import axios from "axios";
import useAuth from "../useAuth";

const Login = () => {
  useAuth(); // Check for authentication and redirect if needed

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const loginUser = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}api/students/login`, {
        email,
        password,
      }, {
        headers: {
          "Content-type": "application/json",
        },
      });
  
      const data = response;
      console.log(data);
  
      if (data.status==200) {
        // Save user details in localStorage
        localStorage.setItem('token', data?.data?.token);
        localStorage.setItem("user", JSON.stringify({
          email: data.data.email,
          name: data.data.name,
          role: data.data.role,
          grade:data.data.grade,
          uniqueId: data.data.uniqueId,
          timezone: data.data.timezone,
          referralCode:data.data.referralCode
        }));        navigate("/");
      } else {
        alert("Invalid email or password");
      }
    } catch (error) {
      console.error("Login failed", error);
      alert("An error occurred while trying to log in. Please try again.");
    }
  };
  return (
    <div className="app-container">
      <div className="image-section">
        <img className="background-img" src={portrait} alt="Background" />
        <img className="logo" src={oratricslogo} alt="Oratrics Logo" />
      </div>
      <div className="login-section">
        <h1 className="welcome-text" style={{ color: '#050a30' }}>
          Welcome to <span style={{ color: '#8b0000' }}>Oratrics</span>
        </h1>
        <form className="mx-auto mt-4 text-left" onSubmit={loginUser}>
        <div className="mb-8">
            <h2 className="text-3xl font-bold mb-2 text-gray-600 text-left" style={{ color: '#050a30' }}>
              Login
            </h2>
            {/* <p className="text-gray-500 text-left" style={{ color: '#050a30' }}>
              Need an account?{" "}
              <span
                onClick={() => navigate("/register")}
                className="text-lime-500 cursor-pointer"
                style={{ color: '#8b0000' }}
              >
                Sign Up
              </span>
            </p> */}
          </div>
          <div className="text-left">
            <label className="block mb-1">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              autoComplete="email"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
              autoComplete="current-password"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="flex justify-between items-center">
            <Button
              type="submit"
              className="bg-lime-500 text-white py-2 px-4 rounded-sm"
            >
              Login
            </Button>
          
          </div>
            <span className="text-sm text-gray-500 cursor-pointer">
              Forget password?
            </span>
        </form>
      </div>
    </div>
  );
};

export default Login;
