import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Card,
  Typography,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import baseUrl from '../config';
import { oratricslogo } from '../Assets';

const ViewNotes = () => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseUrl}api/students/getnotes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNotes(response.data);
      } catch (error) {
        setError('Failed to fetch notes. Please try again.');
        console.error('Error fetching notes:', error);
      }
    };

    fetchNotes();
  }, []);
  const downloadPDF = async (note) => {
    const doc = new jsPDF();
    
    // Load the logo image as a Base64 string
    const logoUrl = 'https://via.placeholder.com/150'; // Replace with your logo URL
    const logoBase64 = await getBase64Image(oratricslogo);
    
    // Add the logo to the PDF
    doc.addImage(logoBase64, 'PNG', 10, 10, 50, 25); // Adjust size and position as needed
  
    // Add title
    doc.setFontSize(22);
    doc.text('Note: ' + note.title, 10, 50); // Adjust vertical position for spacing
    doc.setFontSize(12);
    doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 10, 60); // Adjust spacing
    
    // Draw a line
    doc.line(10, 62, 200, 62); // Adjust line position for spacing
    
    // Add content with spacing
    doc.setFontSize(12);
    const contentYPosition = 65; // Starting Y position for content
    const marginBottom = 10; // Space below content
    doc.text(note.content, 10, contentYPosition, { maxWidth: 190 }); // Max width to avoid overflow
    doc.save(`${note.title}.pdf`);
  };
  
  // Helper function to convert image URL to Base64
  const getBase64Image = (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };
  
  

  return (
    <Container style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px', width: '100%' }}>My Notes</Typography>
      {error && <Typography color="error">{error}</Typography>}
      {notes.map((note, index) => (
        <Card 
          key={index} 
          style={{ 
            width: '250px', // Set a fixed width
            height: '250px', // Set a fixed height for a square
            padding: '20px', 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '10px', 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#f9f9f9',
          }}
        >
          <Box>
            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
              {note.title}
            </Typography>
            <Typography variant="body2" style={{ color: '#555', marginTop: '10px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
              {note.content}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <IconButton 
              color="primary" 
              onClick={() => downloadPDF(note)} 
              aria-label="download"
            >
              <DownloadIcon />
            </IconButton>
          </Box>
        </Card>
      ))}
    </Container>
  );
};

export default ViewNotes;
