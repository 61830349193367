import React from 'react';
import { Card, CardContent, Box, Typography, LinearProgress, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';

const ChallengeCard = () => {
  return (
    <Card sx={{ maxWidth: 400, borderRadius: 4, boxShadow: 3 }}>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h6" fontWeight="bold">Achievements</Typography>
          <Typography variant="subtitle2" color="#050a30">New</Typography>
        </Box>

        <Box textAlign="center" mb={2}>
        <LockIcon fontSize="small" />

          {/* <img src="/path/to/your/image.png" alt="Rapid Ranger" style={{ width: 80, height: 80 }} /> */}
          <Typography variant="h5" fontWeight="bold"> Oratory Achievers
          </Typography>
          <Typography variant="body2" color="textSecondary">
          Earn this badge for unyielding consistency, the mark of a true champion.          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={2}>
          {Array.from({ length: 5 }).map((_, index) => (
            <Box key={index} sx={{
              width: 50, height: 50, backgroundColor: 'grey.300', borderRadius: '50%',
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              color: 'white', fontWeight: 'bold'
            }}>
              <LockIcon fontSize="small" />
            </Box>
          ))}
        </Box>

        <Box mb={2}>
          <LinearProgress variant="determinate" value={0} />
        </Box>

        <Typography variant="body2" mb={2}>
          Complete 5 more classes to earn a Level 1 badge
        </Typography>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography variant="body2">24 days left</Typography>
              <Button variant="text" color="primary">Explore badges & eligibility</Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {Array.from({ length: 5 }).map((_, index) => (
              <Box key={index} display="flex" alignItems="center" mb={1}>
                <Box sx={{
                  border: '2px dashed', borderColor: 'primary.main',
                  borderRadius: 2, width: '100%', padding: 1, textAlign: 'center'
                }}>
                  <Typography variant="body2" color="primary">Level {index + 1}</Typography>
                  <Typography variant="caption" display="block">
                    Complete {5 * (index + 1)} classes
                  </Typography>
                </Box>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default ChallengeCard;
