import React from "react";
import { Box,} from '@mui/material';
import SidebarRight from "../Components/SideScreen";
import Dashboards from "../Components/Dashboard";

const DashboardPage = () => {

  return (
    <Box 
    display="flex"  height={{ md: '100vh' }}
    flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >
  {/* Grey Strip for CountryTimeSelector */}

<Dashboards/>   
    

  {/* Sidebar for larger screens (3rd screen) */}
  <SidebarRight/>
  </Box>

  );
};

export default DashboardPage;
